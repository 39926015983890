import { useCallback, useState, useContext } from "react";

import axios from "axios";
import { Grid, Typography, Box, Divider } from "@mui/material";

import { useDropzone } from "react-dropzone";
import { RateLimiter } from "limiter";

import configData from "../Config";
import { AuthTokenContext } from "../components/Auth";

function PhotoUploadArea(props) {
  let [files, setFiles] = useState([]);

  return (
    <Box
      sx={{
        width: 500,
        minHeight: 25,
        border: "1px solid lightgrey",
        padding: 2,
      }}
    >
      <DropArea
        files={files}
        setFiles={setFiles}
        incrementPhotoCount={props.incrementPhotoCount}
      />

      <UploadProgressView files={files} />
    </Box>
  );
}

function DropArea(props) {
  const token = useContext(AuthTokenContext);

  const onDrop = useCallback((acceptedFiles) => {
    let fileProgress = acceptedFiles.map((file) => ({
      fileName: file.name,
      progress: "0 %",
    }));

    let concattedFileProgress = props.files.concat(fileProgress);

    props.setFiles(concattedFileProgress);
    const limiter = new RateLimiter({
      tokensPerInterval: 15,
      interval: 4000,
    });

    acceptedFiles.map((file) => {
      const formData = new FormData();
      formData.append("File", file);
      (async () => {
        try {
          const remainingRequests = await limiter.removeTokens(1);
          if (remainingRequests < 1) {
            await new Promise((r) => setTimeout(r, 2000));
          }

          const response = await axios.post(
            configData.api.host + "/api/v1/photos/",
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );

                const updatedFileProgress = concattedFileProgress.map((f) => {
                  if (f.fileName == file.name) {
                    if (percentCompleted == 100) {
                      f.progress = "✅";
                    } else {
                      f.progress = percentCompleted + "% 🚀";
                    }
                    return f;
                  } else {
                    return f;
                  }
                });
                let sortedFileProgress = updatedFileProgress.sort((a, b) => {
                  if (a.includes("🚀")) {
                    return -1;
                  } else if (b.includes("🚀")) {
                    return 1;
                  } else {
                    return 0;
                  }
                });
                props.setFiles(sortedFileProgress);
              },
            }
          );

          props.incrementPhotoCount();
        } catch (e) {
          const updatedFileProgress = concattedFileProgress.map((f) => {
            if (f.fileName == file.name) {
              f.progress = "❌";
              return f;
            } else {
              return f;
            }
          });
          props.setFiles(updatedFileProgress);
          console.error(e);
        }
      })();
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Typography variant={"h4"}>Drop files here ...</Typography>
      ) : (
        <Typography variant={"h5"}>
          👆 Drag files here or click to select...
        </Typography>
      )}
    </Box>
  );
}
function UploadProgressView(props) {
  let files = props.files;

  return (
    <Box maxHeight="200px" overflow={"scroll"}>
      {files.length > 0 && <Divider />}
      {files.map((file) => (
        <Box key={file.fileName}>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              {file.fileName && (
                <Typography>{file.fileName.slice(0, 20)}</Typography>
              )}
            </Grid>
            <Grid item>
              {file.progress && <Typography>{file.progress}</Typography>}
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
}

export { PhotoUploadArea };
