import { useState, useEffect, useContext } from "react";

import axios from "axios";

import {
  Box,
  Modal,
  Grid,
  ImageListItem,
  ImageList,
  Typography,
} from "@mui/material";
import configData from "../Config";
import Loading from "../components/Loading";
import { AuthTokenContext } from "../components/Auth";
import { AlbumContext } from "./AlbumDetailView";

function AlbumModalView(props) {
  const album = useContext(AlbumContext).album;
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <Grid
        container
        direction="column"
        spacing={3}
        alignItems={"center"}
        sx={{
          bgcolor: "background.paper",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          height: "90%",
          overflow: "scroll",
        }}
      >
        {/* <Grid item>
          <Typography variant="h3">{album.Name}</Typography>
        </Grid>
        <Grid item>
          <Typography>album photos</Typography>
        </Grid> still doesnt work for some reason.*/}
        <AlbumPhotoGridView />
      </Grid>
    </Modal>
  );
}

function AlbumPhotoGridView(props) {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = useContext(AuthTokenContext);
  const album = useContext(AlbumContext).album;
  const refreshAlbum = useContext(AlbumContext).updateAlbum;

  const updatePhotoState = () => {
    (async () => {
      try {
        const response = await axios.get(
          configData.api.host + "/api/v1/photos/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          setPhotos(response.data);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };
  useEffect(updatePhotoState, []);

  const removeImageFromAlbum = (photoID) => {
    (async () => {
      try {
        const response = await axios.delete(
          configData.api.host +
            "/api/v1/albums/" +
            album.ID +
            "/photos/" +
            photoID,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          refreshAlbum();
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const addImageToAlbum = (photoID) => {
    (async () => {
      try {
        const response = await axios.patch(
          configData.api.host +
            "/api/v1/albums/" +
            album.ID +
            "/photos/" +
            photoID,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          updatePhotoState();
          refreshAlbum();
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  return (
    <Grid container ml={2.3}>
      <Grid item width={"50%"}>
        <ImageList cols={6}>
          {album.Photos.map((photo) => (
            <ImageListItem
              key={photo.ID}
              onClick={() => removeImageFromAlbum(photo.StorageKey)}
            >
              <AlbumPhotoGridThumbnailView photoID={photo.StorageKey} />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
      <Grid item width={"50%"}>
        <ImageList variant="quilted" cols={6} gap={8} width={"50%"}>
          {photos.map((photo) => (
            <ImageListItem
              key={photo.id}
              onClick={() => {
                addImageToAlbum(photo.id);
              }}
            >
              <AlbumPhotoGridThumbnailView photoID={photo.id} />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
    </Grid>
  );
}

function AlbumPhotoGridThumbnailView(props) {
  const [loading, setLoading] = useState(true);
  const [thumbnailData, setThumbnailData] = useState("");
  const token = useContext(AuthTokenContext);
  const getPhoto = () => {
    (async () => {
      try {
        const response = await axios.get(
          configData.api.host + "/api/v1/thumbnails/" + props.photoID,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          setThumbnailData(response.data.thumbnailData);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };
  useEffect(getPhoto, [props.photoID]);
  if (loading) {
    return (
      <Box p={4}>
        <Loading />
      </Box>
    );
  }

  return (
    <Box
      component="img"
      sx={{
        height: 75,
        width: 75,
        borderRadius: "8px",
        objectFit: "cover",
      }}
      src={`data:image/jpeg;base64,${thumbnailData}`}
    ></Box>
  );
}

export { AlbumModalView };
