import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Modal, Box, Grid } from "@mui/material";
import { AuthTokenContext } from "../components/Auth";

import configData from "../Config";

const modalStyle = {
  position: "absolute",
  flex: 1,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ImageDetailModal(props) {
  let [photoData, setPhotoData] = useState("");
  let [loading, setLoading] = useState(true);
  const authToken = useContext(AuthTokenContext);
  useEffect(() => {
    (async () => {
      try {
        axios
          .get(
            configData.api.host +
              "/api/v1/photos/" +
              props.selectedPhotoId +
              "/data",
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          )
          .then((response) => {
            const base64String = btoa(response.data.data);
            setPhotoData(response.data.data);
            setLoading(false);
          })
          .catch((e) => {
            console.error(e);
          });
      } catch (e) {
        console.log(e);
      }
    })();
  }, [props.selectedPhotoId]);

  if (loading) {
    return (
      <Modal open={props.modalOpen} onClose={props.onClose}>
        <Box p={4}>
          <h1>Loading...</h1>
        </Box>
      </Modal>
    );
  }

  const onClose = () => {
    props.onClose();
    setPhotoData("");
    props.selectedPhotoId = "";
  };
  // TODO center this stupid thing
  return (
    <Modal
      open={props.modalOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={modalStyle}
    >
      <Box
        sx={{
          backgroundColor: "#323232",
          transform: "translate(8%, 5%)",
        }}
        height="80vh"
        width="80vw"
        m={5}
      >
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            width="80%"
            item
            backgroundColor="#252525"
          >
            <Grid item height={"80vh"} width="auto" mt={-2} ml={-2}>
              <Box
                maxWidth="100%"
                maxHeight="100%"
                component="img"
                src={`data:image/jpeg;base64,${photoData}`}
              ></Box>
            </Grid>
          </Grid>
          <Grid item minWidth="200px" width="20%" color={"#fefefe"}>
            <h1>Photo Details</h1>
            <p>Photo ID: {props.selectedPhotoId}</p>
            <p>Photo Name: {props.selectedPhotoName}</p>
            <p>Photo Description: {props.selectedPhotoDescription}</p>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export { ImageDetailModal };
