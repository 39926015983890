import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import configData from "../Config";
import { Typography, Grid, Box } from "@mui/material";

function AlbumPublicView(props) {
  let params = useParams();
  const [album, setAlbum] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadAlbum = () => {
    (async () => {
      try {
        const response = await axios.get(
          configData.api.host + "/api/v1/shared/" + params.id
        );

        setAlbum(response.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  };
  useEffect(loadAlbum, []);
  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems={"center"}
      mt={3}
      spacing={3}
      width={"95vw"}
    >
      <Grid item>
        <Typography variant="h1" color={"#4A4B4F"}>
          {album.Name}
        </Typography>
      </Grid>
      {album.Photos.map((photo) => (
        <Grid item key={photo.ID} ml={2}>
          <Box
            component="img"
            maxWidth={"90vw"}
            maxHeight={"80vh"}
            src={photo.PublicURL}
          ></Box>
        </Grid>
      ))}
    </Grid>
  );
}
export { AlbumPublicView };
