import { Grid, Typography, Link } from "@mui/material";

export default function UncommonFooter() {
  return (
    <Grid container direction="row" mb={3}>
      <Grid item>
        <Typography variant={"body2"}>
          Made with heartEmoji by{" "}
          <Link href="https://uncommon.industries/">Uncommon Industries</Link>
        </Typography>
      </Grid>
    </Grid>
  );
}
