import "./App.css";

import { Container } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

import { LoggedInMainView } from "./LoggedInMainView";
import { LoggedOutMainView } from "./LoggedOutMainView";

function App() {
  const { isAuthenticated } = useAuth0();
  return <>{isAuthenticated ? <LoggedInMainView /> : <LoggedOutContainer />}</>;
}

function LoggedOutContainer() {
  return (
    <Container>
      <LoggedOutMainView />
    </Container>
  );
}
export default App;
