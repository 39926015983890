import { Grid, Typography } from "@mui/material";

function SettingsView() {
  return (
    <Grid container direction="column" alignItems={"center"}>
      <Grid item>
        <Typography variant="h3">Settings</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1"> TODO Settings page </Typography>
      </Grid>
    </Grid>
  );
}
export { SettingsView };
