import { useState, useContext, createContext, useEffect } from "react";

import {
  Grid,
  Box,
  Button,
  Typography,
  FormControlLabel,
  Link,
  Switch,
  ImageList,
  ImageListItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";

import { AuthTokenContext } from "../components/Auth";
import { AlbumModalView } from "./AlbumModalView";
import configData from "../Config";

const AlbumContext = createContext({ album: null, updateAlbum: null });

function AlbumDetailView(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [album, setAlbum] = useState(props.album);
  const [publicID, setPublicID] = useState(props.album.PublicID);
  const [published, setPublished] = useState(props.album.PublicID != null);
  const authToken = useContext(AuthTokenContext);
  const togglePublicState = () => {
    setPublished(!published);
    (async () => {
      try {
        const response = await axios.post(
          configData.api.host + "/api/v1/albums/" + props.album.ID + "/share",
          {},
          {
            headers: {
              Authorization: "Bearer " + authToken,
            },
          }
        );
        setPublished(response.data.PublicID != null);
        setPublicID(response.data.PublicID);
      } catch (error) {
        console.log(error);
      }
    })();
  };

  const updateAlbum = () => {
    (async () => {
      try {
        const response = await axios.get(
          configData.api.host + "/api/v1/albums/" + album.ID,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status == 200) {
          setAlbum(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  };
  const [dialogOpened, setDialogOpened] = useState(false);
  const openDeleteConfirmationDialogue = () => {
    setDialogOpened(true);
  };
  const closeDeleteConfirmationDialogue = () => {
    setDialogOpened(false);
  };
  const deleteAlbum = () => {
    (async () => {
      try {
        const response = await axios.delete(
          configData.api.host + "/api/v1/albums/" + album.ID,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status == 200) {
          window.location.reload(false);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  };

  useEffect(updateAlbum, []);

  return (
    <AlbumContext.Provider value={{ album: album, updateAlbum: updateAlbum }}>
      <Grid
        container
        direction="column"
        alignItems={"center"}
        spacing={5}
        width={"100%"}
        mt={3}
      >
        <AlbumModalView open={open} handleClose={handleClose} />
        <Grid container maxWidth={"60%"} spacing={3} alignItems={"center"}>
          <Grid item>
            <Button variant="outlined" onClick={props.deselectAlbum}>
              <Typography>🔙</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="h3" color={"#4A4B4F"}>
              {album.Name}
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch checked={published} onChange={togglePublicState} />
              }
              label="public"
            />
          </Grid>
          {publicID && (
            <Grid item>
              <Link href={"/shared/" + publicID}>Public Link</Link>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          spacing={3}
          mt={3}
          width={"60%"}
        >
          <Dialog
            open={dialogOpened}
            onClose={closeDeleteConfirmationDialogue}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Delete Album?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this album? This action cannot
                be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteConfirmationDialogue}>Cancel</Button>
              <Button onClick={deleteAlbum} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Grid item>
            <Button variant="contained" onClick={handleOpen}>
              <Typography>Add photos to album</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={openDeleteConfirmationDialogue}
            >
              <Typography>Delete album</Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid item>
          <AlbumPreviewView album={album} />
        </Grid>
      </Grid>
    </AlbumContext.Provider>
  );
}

function AlbumPreviewView(props) {
  // given an album object- display a preview of the images in the album.
  // if the album is empty, display a message saying so.
  return (
    <Grid
      container
      direction="column"
      alignItems={"center"}
      spacing={3}
      width={"100%"}
      mt={3}
      ml={2}
    >
      <Grid item>
        <ImageList variant="masonry" cols={4} gap={3}>
          {props.album.Photos.map((photo) => (
            <ImageListItem key={photo.ID}>
              <AlbumPreviewViewThumbnail photo={photo} />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
    </Grid>
  );
}

function AlbumPreviewViewThumbnail(props) {
  const authToken = useContext(AuthTokenContext);
  const [photoData, setPhotoData] = useState("");
  const getImageData = () => {
    (async () => {
      try {
        const response = await axios.get(
          configData.api.host +
            "/api/v1/photos/" +
            props.photo.StorageKey +
            "/data",
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status == 200) {
          setPhotoData(response.data.data);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  };
  useEffect(getImageData, []);

  return (
    <Box
      component="img"
      src={`data:image/jpeg;base64,${photoData}`}
      width={248}
      objectFit="crop"
    ></Box>
  );
}

export { AlbumDetailView, AlbumContext };
