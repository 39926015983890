import { createContext, useState } from "react";

import CornerRibbon from "react-corner-ribbon";

import { useAuth0 } from "@auth0/auth0-react";

import { Box, Grid, Typography } from "@mui/material";

import LoadingView from "./components/Loading";

import { Outlet } from "react-router-dom";

import { SideNav } from "./components/SideNav";

import NeedSubscriptionView from "./Onboarding/NeedSubscriptionView";
import configData from "./Config";
import { useApi } from "./ApiClient/FetchResource";
import { AuthTokenContext } from "./components/Auth";

function ReadyToRockAndRollView() {
  return (
    <Box sx={{ display: "flex" }}>
      <CornerRibbon
        position="top-right"
        fontColor="#f0f0f0"
        backgroundColor="#2c7"
      >
        beta
      </CornerRibbon>
      <SideNav />
      <Outlet />
    </Box>
  );
}

function ExportView() {
  return (
    <Grid container direction="column" alignItems={"center"}>
      <Grid item>
        <Typography variant="h3">Export</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1"> TODO Export page </Typography>
      </Grid>
    </Grid>
  );
}

const SubscriptionContext = createContext({});

function LoggedInMainView() {
  const {
    loading,
    error,
    refresh,
    data: subscription,
  } = useApi("subscriptions"); // use API is a little hacky.
  const [token, setToken] = useState("");

  const { getAccessTokenSilently } = useAuth0();

  (async () => {
    await getAccessTokenSilently({
      audience: configData.auth.apiAudienceIdentifier,
    }).then((response) => {
      setToken(response);
    });
  })();

  if (loading) {
    return <LoadingView />;
  }
  if (error) {
    if (error.response.status == 404) {
      return (
        <AuthTokenContext.Provider value={token}>
          <NeedSubscriptionView />
        </AuthTokenContext.Provider>
      );
    }
    if (subscription.Status != "Active") {
      return (
        <AuthTokenContext.Provider value={token}>
          <NeedSubscriptionView />
        </AuthTokenContext.Provider>
      );
    }
  }
  return (
    <AuthTokenContext.Provider value={token}>
      <SubscriptionContext.Provider value={subscription}>
        <ReadyToRockAndRollView />
      </SubscriptionContext.Provider>
    </AuthTokenContext.Provider>
  );
}

export { LoggedInMainView, ExportView, SubscriptionContext };
