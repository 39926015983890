import { Box, Button, Container, Grid, Typography, Link } from "@mui/material";
import UncommonFooter from "./components/UncommonFooter";

function AboutPage() {
  return (
    <Container>
      <Grid container direction={"column"} spacing={3} mt={3}>
        <Grid item>
          <Link href={"/"} sx={{ textDecoration: "none" }}>
            <Button color="info">
              <Typography
                variant={"body-2"}
                sx={{ color: "#474747", textDecoration: "none" }}
              >
                ⬅️ Back
              </Typography>
            </Button>
          </Link>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={6}>
            <Box
              borderWidth="2px"
              sx={{
                backgroundColor: "#F2F3F6",
                borderRadius: "0.5rem",
                filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))",
              }}
              p={5}
            >
              <Grid container direction={"column"} spacing={3}>
                <Grid item>
                  <Typography variant="h4">About</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    <Link href="https://uncommon.industries">
                      Uncommon Industries
                    </Link>{" "}
                    is a group of scientists, engineers, artists, and designers.
                    We were frustrated with the price, and quality of photo
                    storage.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    We were buying dozens of 4Tb hard drives, and spending way
                    too much time trying to configuring NAS's and servers in our
                    homes and offices.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    We were also sick of most photo sharing sites down sampling
                    all our shots to a lower resolution.
                  </Typography>
                  <Typography variant="h6">
                    We fought hard for our pixels.
                  </Typography>
                  <Typography variant="h6">
                    We should be able to share every single last one of them.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    We wanted to build a better photo sharing and storage
                    platform.
                  </Typography>
                  <Typography variant="h6">So we did.</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              component="img"
              borderRadius="0.5rem"
              filter="drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))"
              width={"100%"}
              src="https://production-uncommon-photos.s3.us-west-2.amazonaws.com/photos/1ce594af-3c8c-46fc-8a33-7db025421c23/photo"
            ></Box>
          </Grid>
        </Grid>
        <Grid item container direction="column" justifyContent={"flex-end"}>
          <Grid item>
            <UncommonFooter />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export { AboutPage };
