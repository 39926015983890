import { useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Drawer,
  Divider,
  ListItem,
  List,
  Typography,
  Grid,
  Box,
} from "@mui/material";

import { LogoutButton } from "./Auth";
import configData from "../Config";
import { AuthTokenContext } from "./Auth";

function SideNav() {
  const token = useContext(AuthTokenContext);

  const activatePortal = () => {
    axios
      .post(
        configData.api.host + "/api/v1/portal-session",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        window.location.replace(response.data.portalURL);
      });
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: 200,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 200,
          boxSizing: "border-box",
        },
      }}
    >
      <Link to="/">
        <Box
          my={1}
          component="img"
          width={200}
          src="https://i.imgur.com/Qvw2VPN.png"
        />
      </Link>

      <Divider />
      <List>
        <Link to="/albums" style={{ color: "#474747", textDecoration: "none" }}>
          <ListItem button>
            <Typography variant="h6">📚 Albums</Typography>
          </ListItem>
        </Link>

        <ListItem button onClick={activatePortal}>
          <Typography variant="h6">💸 Billing</Typography>
        </ListItem>

        <Link
          to="/settings"
          style={{ color: "#474747", textDecoration: "none" }}
        >
          <ListItem button>
            <Typography variant="h6">⚙️ Settings</Typography>
          </ListItem>
        </Link>

        <Link
          to="/feedback"
          style={{ color: "#474747", textDecoration: "none" }}
        >
          <ListItem button>
            <Typography variant="h6">🗣️ Feedback</Typography>
          </ListItem>
        </Link>
      </List>
      <Grid
        height={"100%"}
        mb={3}
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item>
          <LogoutButton />
        </Grid>
      </Grid>
    </Drawer>
  );
}

export { SideNav };
