import { Button, Grid, Typography, Box, Link } from "@mui/material";
import { List, ListItem, ListItemText } from "@mui/material";

import { LoginButton } from "./components/Auth";
import UncommonFooter from "./components/UncommonFooter";

function LoggedOutMainView() {
  return (
    <Grid
      container
      direction="column"
      justifyContent={"center"}
      alignItems={"center"}
      spacing={5}
      mt={0}
    >
      <Grid item width="90%" mb={2}>
        <Grid
          container
          height={5}
          spacing={3}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Grid item>
            <Link
              href="/about"
              style={{ color: "#474747", textDecoration: "none" }}
            >
              <Button>
                <Typography variant="h6" color="#474747">
                  About
                </Typography>
              </Button>
            </Link>
          </Grid>

          <Grid item>
            <LoginButton value={"Log in"} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box
          component="img"
          maxWidth={{ xs: "338px", md: "100%" }}
          src="https://i.imgur.com/Qvw2VPN.png"
        />
      </Grid>

      <Grid item maxWidth={{ xs: "338px", md: "740px" }}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant={"h4"} align="center" mb={1} color={"#474747"}>
              Store, View and Share your high res DSLR photos in the cloud.
            </Typography>
          </Grid>
          <Grid>
            <Typography variant={"h4"} color={"#474747"}>
              No more harddrives.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={"body-2"} color={"#474747"}>
              (Yes, even your RAW format files.)
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box
          component="img"
          maxWidth={{ xs: "311px", md: "740px" }}
          borderRadius={"0.5rem"}
          sx={{ filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))" }}
          src="https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F45cffb50-9567-42c7-a292-2dcfa57f86b9%2F136047F3-A538-43B4-9B60-109383958631.jpeg?table=block&id=63fe7b2c-3f52-4093-8a36-fe5f17c260c5&spaceId=5f369146-7e06-4bda-823c-57374e95bc75"
        />
      </Grid>
      <Grid item>
        <Typography color={"#474747"}>
          Simple Pricing. No setup fees. No hidden fees.
        </Typography>
      </Grid>
      <Grid item>
        <LoginButton value={"Sign me up, Pal!"} />
      </Grid>
      <Grid item mx={2}>
        <ValuePropCard />
      </Grid>
      <Grid item>
        <Typography color={"#474747"}>
          Start for as little as $1 today
        </Typography>
      </Grid>

      <Grid item xs={12} md={12}>
        <UncommonFooter />
      </Grid>
    </Grid>
  );
}

function ValuePropCard() {
  return (
    <Box
      sx={{
        padding: "1rem",
        backgroundColor: "#F2F3F6",
        borderRadius: "0.5rem",
        filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))",
      }}
    >
      <Grid container direction="column" alignItems={"flex-start"}>
        <Grid item>
          <Typography variant={"h5"} color={"#474747"}>
            Great for:
          </Typography>
        </Grid>
        <Grid item>
          <List>
            <ListItem>
              <ListItemText
                color={"#474747"}
                primary="💒 That 45 gb of wedding photos you dont know where to back up"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                color={"#474747"}
                primary="😎 People who are privacy nuts and dont trust IG with their photos"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                color={"#474747"}
                primary="📷 People who just bought a SonyA7R4 and accidentally took 187 Gb of photos around Rome"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                color={"#474747"}
                primary="💾 People who are lazy and want to save money by not buying 1tb harddrives"
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
}

export { LoggedOutMainView };
