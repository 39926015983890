import { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Grow,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import axios from "axios";

import { useForm } from "react-hook-form";

import configData from "../Config";
import Loading from "../components/Loading";
import { CreateFirstAlbumView } from "./CreateFirstAlbumView";
import { AlbumDetailView } from "./AlbumDetailView";
import { AuthTokenContext } from "../components/Auth";

function AlbumsView() {
  const [albums, setAlbums] = useState({});

  const [loading, setLoading] = useState(true);
  const [selectedAlbum, setSelectedAlbum] = useState(null);

  const deselectAlbum = () => {
    setSelectedAlbum(null);
  };
  const authToken = useContext(AuthTokenContext);

  const getAlbums = () => {
    (async () => {
      try {
        const response = await axios.get(
          configData.api.host + "/api/v1/albums/",
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status == 200) {
          setAlbums(response.data);

          setLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  };

  useEffect(getAlbums, []);

  if (loading) {
    return (
      <Box p={4}>
        <Loading />
      </Box>
    );
  }

  return (
    <>
      {selectedAlbum ? (
        <AlbumDetailView album={selectedAlbum} deselectAlbum={deselectAlbum} />
      ) : (
        <Grid
          container
          direction="column"
          alignItems={"center"}
          spacing={3}
          width={"100%"}
        >
          <Grid item mt={4}>
            <Typography variant="h3" color={"#4A4B4F"}>
              Albums
            </Typography>
          </Grid>
          {albums.length == 0 && (
            <Grid item>
              <CreateFirstAlbumView refreshAlbumView={getAlbums} />
            </Grid>
          )}
          {albums.length > 0 && (
            <Grid item>
              <AlbumListView
                albumList={albums}
                refreshAlbumView={getAlbums}
                setSelectedAlbum={setSelectedAlbum}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

function AlbumListView(props) {
  const setAlbum = (album) => {
    props.setSelectedAlbum(album);
  };
  return (
    <List>
      <AddAlbumView refreshAlbumView={props.refreshAlbumView} />
      {props.albumList.map((album) => (
        <ListItem width={"100%"} key={album.ID}>
          <ListItemButton
            onClick={(event) => setAlbum(album)}
            sx={{
              borderWidth: 1,
              borderRadius: 4,
            }}
          >
            <Box width={"90vh"} height={72}>
              <Typography>{album.Name}</Typography>
            </Box>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
function AddAlbumView(props) {
  const [createNewAlbum, setCreateNewAlbum] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const authToken = useContext(AuthTokenContext);
  const submitNewAlbumForm = (formData) => {
    console.log(formData);
    if (formData.name == "") {
      formData.name = "Untitled Album";
    }

    (async () => {
      try {
        let response = await axios.post(
          configData.api.host + "/api/v1/albums",
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status == 200) {
          console.log(response.data);
          props.refreshAlbumView();
          setCreateNewAlbum(false);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  };
  return (
    <form onSubmit={handleSubmit(submitNewAlbumForm)}>
      {createNewAlbum ? (
        <Grow in={createNewAlbum} {...(createNewAlbum ? { timeout: 500 } : {})}>
          <ListItem>
            <ListItemButton
              sx={{
                borderWidth: 1,
                borderRadius: 4,
              }}
              p={2}
            >
              <Grid container alignItems="center" spacing={1} height={72}>
                <Grid item>
                  <TextField
                    variant="standard"
                    {...register("name")}
                    label="Album Name"
                  />
                </Grid>
                <Grid item>
                  <Button type="submit">
                    <Typography variant="h5">✅</Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      setCreateNewAlbum(false);
                    }}
                  >
                    <Typography variant="h5">❌</Typography>
                  </Button>
                </Grid>
              </Grid>
            </ListItemButton>
          </ListItem>
        </Grow>
      ) : (
        <ListItem width={"100%"}>
          <ListItemButton
            sx={{
              borderWidth: 1,
              borderStyle: "dashed",
              borderRadius: 4,
              borderColor: "#DDDEE3",
            }}
            width={"90vh"}
            onClick={() => setCreateNewAlbum(true)}
          >
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              height={55}
            >
              <Grid item>
                <Typography variant="h4">➕</Typography>
              </Grid>
            </Grid>
          </ListItemButton>
        </ListItem>
      )}
    </form>
  );
}

export { AlbumsView };
