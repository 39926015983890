// use-api.js
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import configData from "../Config";

export const useApi = (resource) => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState({
    error: null,
    loading: true,
    data: null,
  });
  const apiBaseRoute = configData.api.host + "/api/v1/";
  const resourcePath = new URL(resource, apiBaseRoute).href;

  const [refreshIndex, setRefreshIndex] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: configData.auth.apiAudienceIdentifier,
        });
        const response = await axios.get(resourcePath, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setState({
          ...state,
          data: await response.data,
          error: null,
          loading: false,
        });
      } catch (error) {
        setState({
          ...state,
          error,
          loading: false,
        });
      }
    })();
  }, [refreshIndex]);

  return {
    ...state,
    refresh: () => setRefreshIndex(refreshIndex + 1),
  };
};
