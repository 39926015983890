import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "@mui/material";
import { createContext } from "react";

const LoginButton = (props) => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Button variant="contained" onClick={() => loginWithRedirect()}>
      <Typography variant="h6">{props.value}</Typography>
    </Button>
  );
};

const LogoutButton = (props) => {
  const { logout } = useAuth0();

  return (
    <Button
      variant="contained"
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      <Typography>👋 Log Out</Typography>
    </Button>
  );
};

const AuthTokenContext = createContext("");

export { LoginButton, LogoutButton, AuthTokenContext };
