import { useState, useEffect, useContext } from "react";

import { LinearProgress, Box, Typography } from "@mui/material";
import axios from "axios";

import configData from "../Config";
import { SubscriptionContext } from "../LoggedInMainView";
import { AuthTokenContext } from "../components/Auth";

function PhotoUsageView(props) {
  const [state, setState] = useState({
    error: null,
    loading: true,
    data: null,
  });

  const token = useContext(AuthTokenContext);
  const apiBaseRoute = configData.api.host + "/api/v1/";
  const resourcePath = new URL("photos?totalVolume=true", apiBaseRoute).href;

  const subscription = useContext(SubscriptionContext);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(resourcePath, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setState({
          ...state,
          data: await response.data,
          error: null,
          loading: false,
        });
      } catch (error) {
        setState({
          ...state,
          error,
          loading: false,
        });
      }
    })();
  }, [props.photoCount]);
  if (state.loading) {
    return (
      <Box>
        <Typography variant="h6">Photo Usage</Typography>
        <LinearProgress />
      </Box>
    );
  }
  const usageValue =
    (state.data / (subscription.Volume * 1024 * 1024 * 1024)) * 100;
  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h6" gutterBottom>
        Photo Usage
      </Typography>
      <LinearProgress variant="determinate" value={usageValue} />

      <Typography variant="body1">
        💾 {formatBytes(state.data)} stored
      </Typography>
    </Box>
  );
}

// stolen from stack overflow but very cute.
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export { PhotoUsageView };
