import { useState, useEffect, useContext } from "react";

import axios from "axios";
import { Grid, Box, Container } from "@mui/material";
import LazyLoad from "react-lazyload";

import { AuthTokenContext } from "../components/Auth";
import { ImageDetailModal } from "./PhotoDetailView";
import { PhotoUploadArea } from "./PhotoUpload";
import { PhotoUsageView } from "./PhotoUsageView";
import configData from "../Config";
import Loading from "../components/Loading";

function PhotoGridView() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPhotoId, setSelectedPhotoId] = useState("");
  const [photoCount, setPhotoCount] = useState(0);

  const incrementPhotoCount = () => {
    setPhotoCount(photoCount + 1);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const handleOpen = (id) => {
    setSelectedPhotoId(id);
    setModalOpen(true);
  };

  return (
    <Container>
      <ImageDetailModal
        selectedPhotoId={selectedPhotoId}
        onClose={handleClose}
        modalOpen={modalOpen}
      />
      <Grid
        item
        container
        direction="column"
        justifyContent={"center"}
        alignItems={"center"}
        spacing={3}
        mt={0}
      >
        <Grid item>
          <PhotoUploadArea incrementPhotoCount={incrementPhotoCount} />
        </Grid>
        <Grid item width="550px">
          <PhotoUsageView photoCount={photoCount} />
        </Grid>
        <Grid item>
          <PhotoGrid
            handleClick={handleOpen}
            photoCount={photoCount}
            setPhotoCount={setPhotoCount}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

function PhotoGrid(props) {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = useContext(AuthTokenContext);
  const updatePhotoState = () => {
    (async () => {
      try {
        const response = await axios.get(
          configData.api.host + "/api/v1/photos/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          setPhotos(response.data);
          props.setPhotoCount(response.data.length);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };
  useEffect(updatePhotoState, [props.photoCount]);
  if (loading) {
    return (
      <Box p={4}>
        <Loading />
      </Box>
    );
  }

  return (
    <Grid container spacing={0.25}>
      {photos.map((photo, index) => (
        <Grid item key={index} onClick={() => props.handleClick(photo.id)}>
          <LazyLoad key={index} height={100}>
            <PhotoGridThumbnail photoID={photo.id} />
          </LazyLoad>
        </Grid>
      ))}
    </Grid>
  );
}

function PhotoGridThumbnail(props) {
  const token = useContext(AuthTokenContext);
  const [loading, setLoading] = useState(true);
  const [thumbnailData, setThumbnailData] = useState("");
  const getPhoto = () => {
    (async () => {
      try {
        const response = await axios.get(
          configData.api.host + "/api/v1/thumbnails/" + props.photoID,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          setThumbnailData(response.data.thumbnailData);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };
  useEffect(getPhoto, [props.photoID]);

  if (loading) {
    return (
      <Box p={4}>
        <Loading />
      </Box>
    );
  }

  return (
    <Box
      component="img"
      sx={{
        height: 140,
        width: 140,
        objectFit: "cover",
      }}
      src={`data:image/jpeg;base64,${thumbnailData}`}
    ></Box>
  );
}

export { PhotoGridView, PhotoGrid };
