import { Grid } from "@mui/material";
import { CircularProgress } from "@mui/material";

export default function LoadingView() {
  return (
    <Grid container width="100%" height="100%">
      <Grid item justifyContent={"center"} alignItems={"center"}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}
