import { createContext, useState, useContext } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import configData from "../Config";
import axios from "axios";
import { AuthTokenContext } from "../components/Auth";

import { LogoutButton } from "../components/Auth";

const SubscriptionModeContext = createContext({});

export default function NeedSubscriptionView() {
  const [subscriptionMode, setSubscriptionMode] = useState("monthly");

  return (
    <SubscriptionModeContext.Provider
      value={{ subscriptionMode, setSubscriptionMode }}
    >
      <Grid
        container
        direction="column"
        alignItems={"center"}
        justifyContent={"center"}
        spacing={5}
        mt={0}
        p={2}
      >
        <Grid item>
          <Box
            component="img"
            maxWidth={{ xs: "338px", md: "100%" }}
            src="https://i.imgur.com/Qvw2VPN.png"
          />
        </Grid>
        <Grid item>
          <Typography variant={"h2"} sx={{ color: "#474747" }}>
            Find the plan that works for you.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={"h5"} sx={{ color: "#474747" }}>
            Subscribe to a plan to get started. Cancel anytime.
          </Typography>
        </Grid>
        <Grid item>
          <ToggleAnnualSubscriptionView />
        </Grid>
        <Grid item>
          <SubscriptionView />
        </Grid>
        <Grid item>
          <Box
            component="img"
            maxWidth={{ xs: "311px", md: "950px" }}
            borderRadius={"0.5rem"}
            sx={{ filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))" }}
            src="https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F45cffb50-9567-42c7-a292-2dcfa57f86b9%2F136047F3-A538-43B4-9B60-109383958631.jpeg?table=block&id=63fe7b2c-3f52-4093-8a36-fe5f17c260c5&spaceId=5f369146-7e06-4bda-823c-57374e95bc75"
          />
        </Grid>
        <Grid item>
          <LogoutButton />
        </Grid>
        <Grid item>
          <Typography color="#f2f2f2">
            © Uncommon Industries. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </SubscriptionModeContext.Provider>
  );
}

function SubscriptionView() {
  const subscriptions = [
    {
      volume: "100Gb",
      monthly: {
        price: "$1",
        price_id: configData.payments.monthly100GBPriceID,
      },
      annual: {
        price: "$10",
        price_id: configData.payments.annual100GBPriceID,
      },
    },
    {
      volume: "1.1Tb",
      monthly: {
        price: "$6",
        price_id: configData.payments.monthly1TbPriceID,
        primary: true,
      },
      annual: {
        price: "$59",
        price_id: configData.payments.annual1TbPriceID,
        primary: true,
      },
    },
    {
      volume: "2.1Tb",
      monthly: {
        price: "$11",
        price_id: configData.payments.monthly2TbPriceID,
      },
      annual: { price: "$99", price_id: configData.payments.annual2TbPriceID },
    },
    {
      volume: "4.1Tb",
      monthly: {
        price: "$21",
        price_id: configData.payments.monthly4TbPriceID,
      },
      annual: {
        price: "$199",
        price_id: configData.payments.annual4TbPriceID,
      },
    },
  ];

  return (
    <Grid
      container
      direction="row"
      alignItems={"center"}
      justifyContent={"center"}
      mt={0}
      spacing={5}
    >
      {subscriptions.map((subscription) => (
        <Grid item key={subscription.volume}>
          <SubscriptionBox subscription={subscription} />
        </Grid>
      ))}
    </Grid>
  );
}

function ToggleAnnualSubscriptionView() {
  const { subscriptionMode, setSubscriptionMode } = useContext(
    SubscriptionModeContext
  );

  const handleChange = (event, newSubscriptionMode) => {
    if (newSubscriptionMode !== null) {
      setSubscriptionMode(newSubscriptionMode);
    }
  };

  return (
    <ToggleButtonGroup
      onChange={handleChange}
      aria-label="subscription mode"
      value={subscriptionMode}
      exclusive
    >
      <ToggleButton value="monthly">
        <Typography>Monthly</Typography>
      </ToggleButton>
      <ToggleButton value="annual">
        <Grid container direction="column">
          <Typography>Annual</Typography>
          <Typography variant={"caption"} color="green">
            Save up to 25%
          </Typography>
        </Grid>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

function SubscriptionBox(props) {
  const { subscriptionMode, _ } = useContext(SubscriptionModeContext);
  const token = useContext(AuthTokenContext);

  const submitCheckoutSessionCreation = (price, token) => {
    axios
      .post(
        configData.api.host + "/api/v1/checkout-session/",
        {
          priceID: price,
          redirectDomain: configData.webserver.domain + "/",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        window.location.replace(response.data.url);
      });
  };

  return (
    <Box
      width={250}
      borderWidth="2px"
      sx={{
        backgroundColor: "#F2F3F6",
        borderRadius: "0.5rem",
        borderStyle: props.subscription[subscriptionMode].primary
          ? "solid"
          : "none",
        borderImage: "linear-gradient(to right, darkblue, darkorchid) 1",
        filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))",
        pb: 5,
      }}
    >
      <Grid
        container
        direction="column"
        alignItems={"center"}
        spacing={5}
        mt={0}
      >
        <Grid item>
          <Typography variant={"h3"} sx={{ color: "#474747" }}>
            {props.subscription.volume}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={"h4"} sx={{ color: "#474747" }}>
            {props.subscription[subscriptionMode].price} / {subscriptionMode}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant={
              props.subscription[subscriptionMode].primary
                ? "contained"
                : "outlined"
            }
            color="primary"
            onClick={() =>
              submitCheckoutSessionCreation(
                props.subscription[subscriptionMode].price_id,
                token
              )
            }
          >
            Get Started
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
