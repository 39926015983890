import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import configData from "./Config";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { PhotoGridView } from "./PhotoView/PhotoGrid";

import { AlbumsView } from "./Albums/AlbumView";
import { AboutPage } from "./About";
import { AlbumPublicView } from "./Albums/AlbumPublicView";
import { SettingsView } from "./Settings/Settings";
import { FeedbackView } from "./Feedback/Feedback";

const root = ReactDOM.createRoot(document.getElementById("root"));
const LOCAL_STORAGE_KEY = "localstorage";
const AppTheme = createTheme({
  typography: {
    fontFamily: ["DM Sans"].join(","),
    color: "#212431",
  },
});

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={configData.auth.authDomain}
      clientId={configData.auth.clientID}
      audience={configData.auth.apiAudienceIdentifier}
      redirectUri={window.location.origin}
      cacheLocation={LOCAL_STORAGE_KEY}
      useRefreshTokens
    >
      <ThemeProvider theme={AppTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              <Route path="/" element={<PhotoGridView />} />
              <Route path="settings" element={<SettingsView />} />
              <Route path="albums" element={<AlbumsView />} />
              <Route path="feedback" element={<FeedbackView />} />
            </Route>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/shared/:id" element={<AlbumPublicView />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
