const productionConfig = {
  webserver: {
    port: "3000",
    domain: "https://photos.uncommon.industries/",
  },
  auth: {
    authDomain: "uncommon-photos-production.us.auth0.com",
    apiAudienceIdentifier: "https://api.UncommonPhotos.com",
    clientID: "N7Am7nAeiKxDRMlapYxVp3dBuZb3eEIq",
  },
  api: {
    host: "https://photos-backend.uncommon.industries",
  },
  payments: {
    monthly100GBPriceID: "price_1LQkkyFCLcUAuMh1xP2CHrel",
    annual100GBPriceID: "price_1LQkkxFCLcUAuMh1RcT7wN0A",
    monthly1TbPriceID: "price_1LQkkyFCLcUAuMh1vvsUAAVY",
    annual1TbPriceID: "price_1LQkkxFCLcUAuMh1wngz0Eff",
    monthly2TBPriceID: "price_1LQkkyFCLcUAuMh1d33U2tas",
    annual2TBPriceID: "price_1LQkkxFCLcUAuMh1GXskqaHK",
    monthly4TBPriceID: "price_1LQkkyFCLcUAuMh1D8xUJd4i",
    annual4TBPriceID: "price_1LQkkxFCLcUAuMh1SN48UU3v",
  },
};

const devConfig = {
  webserver: {
    port: "3000",
    domain: "http://localhost:3000",
  },
  auth: {
    authDomain: "uncommonphotos.us.auth0.com",
    apiAudienceIdentifier: "https://api.UncommonPhotos.com",
    clientID: "IyFEbx9rUzQfWMWJb3jJhMGRfbHYJNqc",
  },
  api: {
    host: "http://localhost:8000",
  },
  payments: {
    monthly100GBPriceID: "price_1LPHdFFCLcUAuMh1eAHlbxSE",
    annual100GBPriceID: "price_1LPKsOFCLcUAuMh1SWKhrXhB",
    monthly1TbPriceID: "price_1LPHdFFCLcUAuMh1nhylchXF",
    annual1TbPriceID: "price_1LPKtJFCLcUAuMh1nWqLOph2",
    monthly2TBPriceID: "price_1LPHdFFCLcUAuMh1q6oujJD1",
    annual2TBPriceID: "price_1LPKuCFCLcUAuMh1RWBCUIhf",
    monthly4TBPriceID: "price_1LPHdFFCLcUAuMh1QpaNlmcg",
    annual4TBPriceID: "price_1LPKulFCLcUAuMh1HRMYlXbG",
  },
};

const stagingConfig = {
  webserver: {
    port: "3000",
    domain: "https://staging-uncommon-photos.vercel.app",
  },
  auth: {
    authDomain: "uncommonphotos.us.auth0.com",
    apiAudienceIdentifier: "https://api.UncommonPhotos.com",
    clientID: "IyFEbx9rUzQfWMWJb3jJhMGRfbHYJNqc",
  },
  api: {
    host: "https://staging-photos-backend.uncommon.industries",
  },
  payments: {
    monthly100GBPriceID: "price_1LPHdFFCLcUAuMh1eAHlbxSE",
    annual100GBPriceID: "price_1LPKsOFCLcUAuMh1SWKhrXhB",
    monthly1TbPriceID: "price_1LPHdFFCLcUAuMh1nhylchXF",
    annual1TbPriceID: "price_1LPKtJFCLcUAuMh1nWqLOph2",
    monthly2TBPriceID: "price_1LPHdFFCLcUAuMh1q6oujJD1",
    annual2TBPriceID: "price_1LPKuCFCLcUAuMh1RWBCUIhf",
    monthly4TBPriceID: "price_1LPHdFFCLcUAuMh1QpaNlmcg",
    annual4TBPriceID: "price_1LPKulFCLcUAuMh1HRMYlXbG",
  },
};

function getConfigData() {
  let env = process.env.REACT_APP_ENV;

  switch (env) {
    case "production":
      return productionConfig;
    case "staging":
      return stagingConfig;
    default:
      return devConfig;
  }
}

const configData = getConfigData();
export default configData;
