import { useState, useContext } from "react";

import { useForm } from "react-hook-form";
import { Grow, Grid, Typography, Button, TextField } from "@mui/material";

import axios from "axios";

import configData from "../Config";
import { AuthTokenContext } from "../components/Auth";

function CreateFirstAlbumView(props) {
  const [clicked, setClicked] = useState(false);
  const clickButton = () => {
    setClicked((prev) => !prev);
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const token = useContext(AuthTokenContext);
  const createAlbum = (data) => {
    console.log(data);
    (async () => {
      try {
        const response = await axios.post(
          configData.api.host + "/api/v1/albums",
          { name: data.name },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status == 200) {
          console.log(response.data);
          props.refreshAlbumView();
        }
      } catch (e) {
        console.log(e);
      }
    })();
  };
  return (
    <Grid container direction="column" alignItems={"center"} spacing={3}>
      {!clicked && (
        <Grid item>
          <Grow in={!clicked} {...(!clicked ? { timeout: 500 } : {})}>
            <Button variant="contained" onClick={clickButton}>
              <Typography>Create your first album</Typography>{" "}
            </Button>
          </Grow>
        </Grid>
      )}
      {clicked && (
        <form onSubmit={handleSubmit(createAlbum)}>
          <Grid
            container
            direction="column"
            alignItems={"center"}
            spacing={3}
            mt={1}
          >
            <Grid item>
              <Grow in={clicked} {...(clicked ? { timeout: 500 } : {})}>
                <TextField
                  label="Album Name"
                  placeholder="Kiran's Vacation Trip"
                  sx={{ minWidth: 500 }}
                  {...register("name")}
                />
              </Grow>
            </Grid>
            <Grid item>
              <Grow in={clicked} {...(clicked ? { timeout: 500 } : {})}>
                <Button variant="contained" type="submit">
                  <Typography>Create Album</Typography>
                </Button>
              </Grow>
            </Grid>
          </Grid>
        </form>
      )}
    </Grid>
  );
}

export { CreateFirstAlbumView };
